<template>
  <section class="section section-standalone" id="ikp">
    <div class="container-fluid my-3">
      <div class="c-dashboard-title d-flex justify-content-between pl-2">
        <h1 class="title-font text-size-title text-uppercase mb-0">
          Instruksi Khusus Pimpinan
        </h1>
      </div>
      <div class="grid-dual-column">
        <div class="grid-dual-column-item-1">
          <div class="bg-white py-2 px-3 border rounded  overflow-hidden">
            <div class="p-3">
              <h4
                class="d-flex align-items-center justify-content-between mb-0 text-size-large titilium-font font-weight-normal"
              >
                Jumlah IKP
              </h4>
            </div>
            <div class="chart-doughnut--wrapper mb-4">
              <canvas id="ikp-chart" width="400" height="200"></canvas>
              <div class="chart-doughnut--text">
                <h2 class="mb-1 text-size-semi-large secondary-font">
                  {{ total_instruksi }}
                </h2>
                <h6
                  class="mb-0 text-uppercase text-size-small font-weight-bold secondary-font"
                >
                  Instruksi
                </h6>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="bg-purple-new py-2 px-3 rounded mb-3">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row c-list-item--dark-blue">
                      <span
                        class="rounded-circle p-1 c-list-indicator c-list-indicator--lg mr-2"
                      >
                        <i class="fas fa-plus"></i>
                      </span>
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="font-weight-bold secondary-font text-dark-blue d-flex align-items-center
                                            justify-content-end"
                      >
                        {{ jumlah_baru }}
                      </h4>
                      <p
                        class="mb-0 text-size-small secondary-font text-uppercase font-weight-normal"
                      >
                        baru
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="bg-yellow-new py-2 px-3 rounded mb-3">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row c-list-item--warning">
                      <span
                        class="rounded-circle border p-1 c-list-indicator c-list-indicator--lg mr-2"
                      >
                        <i class="fas fa-circle fa-lg"></i>
                      </span>
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="text-warning font-weight-bold d-flex align-items-center justify-content-end"
                      >
                        {{ jumlah_close }}
                      </h4>
                      <p
                        class="mb-0 text-size-small secondary-font text-uppercase font-weight-normal"
                      >
                        Close
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="bg-orange-new py-2 px-3 rounded mb-3">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row c-list-item--orange">
                      <span
                        class="rounded-circle p-1 c-list-indicator c-list-indicator--lg mr-2"
                      >
                        <i class="fas fa-search"></i>
                      </span>
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="text-orange font-weight-bold d-flex align-items-center justify-content-end"
                      >
                        {{ jumlah_verifikasi }}
                      </h4>
                      <p
                        class="mb-0 text-size-small secondary-font text-uppercase font-weight-normal"
                      >
                        VERIFIKASI
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="bg-green-new py-2 px-3 rounded mb-3">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row c-list-item--success">
                      <span
                        class="rounded-circle border p-1 c-list-indicator c-list-indicator--lg mr-2"
                      >
                        <i class="fas fa-check"></i>
                      </span>
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="text-green-new font-weight-normal d-flex align-items-center justify-content-end"
                      >
                        {{ jumlah_done }}
                      </h4>
                      <p
                        class="mb-0 text-size-small secondary-font text-uppercase font-weight-normal"
                      >
                        DONE
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="bg-blue-light-new py-2 px-3 rounded mb-3">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row c-list-item--normal">
                      <span
                        class="rounded-circle icon-blue-light-new p-1 c-list-indicator c-list-indicator--lg mr-2"
                      >
                        <i class="fas fa-arrow-right"></i>
                      </span>
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="text-blue-light font-weight-normal d-flex align-items-center justify-content-end"
                      >
                        {{ jumlah_on_going }}
                      </h4>
                      <p
                        class="mb-0 text-size-small secondary-font text-uppercase font-weight-normal"
                      >
                        ON GOING
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-dual-column-item-2">
          <div
            class="bg-white py-2 px-3 border rounded mb-3 h-100 overflow-hidden"
            style="min-height:500px;margin-right: 20px;"
          >
            <div class="p-3">
              <h4
                class="d-flex align-items-center justify-content-between mb-0 text-size-large titilium-font font-weight-normal head-with-border"
              >
                IKP per SKPD
              </h4>
            </div>
            <ul class="nav nav-tabs nav-content" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="jumlah-skpd-tab"
                  data-toggle="tab"
                  href="#jumlah-skpd"
                  role="tab"
                  aria-controls="jumlah-skpd"
                  aria-selected="true"
                >
                  JUMLAH
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="presentase-skpd-tab"
                  data-toggle="tab"
                  href="#presentase-skpd"
                  role="tab"
                  aria-controls="presentase-skpd"
                  aria-selected="false"
                >
                  PERSENTASE
                </a>
              </li>
            </ul>
            <div class="tab-content tab-content-tabs" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="jumlah-skpd"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div class="card-dashboard-content p-3">
                  <a
                    href="#modalIkp"
                    data-toggle="modal"
                    data-target="#modalIkp"
                    class="d-flex align-items-center mb-3"
                    v-for="(item, key) in IKPperSKPD"
                    :key="key"
                    v-on:click="modalPopUp(item.nama_skpd, item.detail)"
                  >
                    <div class="flex-row mr-3">
                      <div class="rounded-circle border border-success p-2">
                        <img
                          src="@/assets/img/icon/icon-building.svg"
                          alt=""
                          class="img-responsive"
                        />
                      </div>
                    </div>
                    <div class="flex-row w-100">
                      <div
                        class="progress-lable d-flex align-items-center justify-content-between mb-1"
                      >
                        <p
                          class="mb-0 text-capitalize text-body text-size-normal"
                        >
                          {{ item.nama_skpd }}
                        </p>
                        <span
                          class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                          >{{ item.jumlah_ikp }}</span
                        >
                      </div>
                      <div class="progress" style="height: 6px;">
                        <div
                          class="progress-bar bg-dark-blue rounded-right"
                          role="progressbar"
                          :style="
                            `width: ${(item.jumlah_baru / item.jumlah_ikp) *
                              100}%`
                          "
                          aria-valuenow="15"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          v-tooltip="`Baru : ${item.jumlah_baru}`"
                        ></div>
                        <div
                          class="progress-bar bg-success rounded-right"
                          role="progressbar"
                          :style="
                            `width: ${(item.jumlah_done / item.jumlah_ikp) *
                              100}%`
                          "
                          aria-valuenow="15"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          v-tooltip="`Done : ${item.jumlah_done}`"
                        ></div>
                        <div
                          class="progress-bar bg-warning rounded-right"
                          role="progressbar"
                          :style="
                            `width: ${(item.jumlah_verifikasi /
                              item.jumlah_ikp) *
                              100}%`
                          "
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          v-tooltip="`Verifikasi : ${item.jumlah_verifikasi}`"
                        ></div>
                        <div
                          class="progress-bar rounded-right"
                          role="progressbar"
                          style="background-color:#ffd908"
                          :style="
                            `width: ${(item.jumlah_close / item.jumlah_ikp) *
                              100}%`
                          "
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          v-tooltip="`Close : ${item.jumlah_close}`"
                        ></div>
                        <div
                          class="progress-bar rounded-right"
                          role="progressbar"
                          style="background-color:#44b6f0"
                          :style="
                            `width: ${(item.jumlah_on_going / item.jumlah_ikp) *
                              100}%`
                          "
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          v-tooltip="`On Going : ${item.jumlah_on_going}`"
                        ></div>
                      </div>
                    </div>
                  </a>

                  <div class="loadmore mt-3 d-flex justify-content-center">
                    <button
                      class="btn btn-loadmore"
                      v-on:click="limitChange()"
                      v-if="
                        IKPperSKPD.length > 0 && limit < listIKPperSKPD.length
                      "
                    >
                      LOAD MORE
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="presentase-skpd"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div class="card-dashboard-content p-3">
                  <a
                    href="#modalIkp"
                    data-toggle="modal"
                    data-target="#modalIkp"
                    class="d-flex align-items-center mb-3"
                    v-for="(item, key) in IKPperSKPDPersen"
                    :key="key"
                    v-on:click="modalPopUp(item.nama_skpd, item.detail)"
                  >
                    <div class="flex-row mr-3">
                      <div class="rounded-circle border border-success p-2">
                        <img
                          src="@/assets/img/icon/icon-building.svg"
                          alt=""
                          class="img-responsive"
                        />
                      </div>
                    </div>
                    <div class="flex-row w-100">
                      <div
                        class="progress-lable d-flex align-items-center justify-content-between mb-1"
                      >
                        <p
                          class="mb-0 text-capitalize text-body text-size-normal"
                        >
                          {{ item.nama_skpd }}
                        </p>
                        <span
                          class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                          >{{ item.persentase_ikp }}%</span
                        >
                      </div>
                      <div class="progress" style="height: 6px;">
                        <div
                          class="progress-bar bg-success rounded-right"
                          role="progressbar"
                          :style="`width: ${item.persentase_ikp}%`"
                          aria-valuenow="15"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </a>
                  <div class="loadmore mt-3 d-flex justify-content-center">
                    <button
                      class="btn btn-loadmore"
                      v-on:click="limitChangePersen()"
                      v-if="
                        IKPperSKPDPersen.length > 0 &&
                          limitPersen < listIKPperSKPDPersen.length
                      "
                    >
                      LOAD MORE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row sumber-data mt-3"
        v-if="sumber_data != null"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data.pic == null ? '-' : sumber_data.pic
          } <br> 
                              Kontak : ${
                                sumber_data.kontak == null
                                  ? '-'
                                  : sumber_data.kontak
                              }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{ sumber_data.updated_at == null ? '-' : sumber_data.updated_at }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalIkp"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalIkpTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-custom border-0 rounded-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button
              type="button"
              class="close rounded-circle bg-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times fa-xs text-body" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body" v-if="ListPopUpIKP.length > 0">
            <div class="title-w-line--right p-3">
              <h4
                class="mb-0 text-capitalize text-size-large titilium-font font-weight-normal"
              >
                Instruksi Khusus Pimpinan
              </h4>
            </div>
            <div class="p-4">
              <h5 class="titilium-font font-weight-normal mb-3 text-size-large">
                {{ nama_skpd }}
              </h5>
              <ul class="p-0 list-style-type-none c-list">
                <li
                  class="d-flex c-list-item c-list-item--success mb-3"
                  v-for="(item, key) in ListPopUpIKP"
                  :key="key"
                >
                  <div class="c-list-title d-flex align-items-center w-100">
                    <span
                      class="primary-font c-list-number rounded-circle border d-flex align-items-center justify-content-center mr-3"
                    >
                      {{ ++key }}
                    </span>
                    <div class="flex-row flex-1">
                      <div
                        class="progress-lable d-flex align-items-center justify-content-between mb-1"
                      >
                        <p class="mb-0 text-capitalize">{{ item.instruksi }}</p>
                        <span
                          class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                          >{{ item.progress }}%</span
                        >
                      </div>
                      <div class="progress" style="height: 6px;">
                        <div
                          class="progress-bar rounded bg-success"
                          role="progressbar"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          :style="`width: ${item.progress}%`"
                        ></div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: 'IKP'
    };
  },
  data() {
    return {
      sumberTooltip: false,
      sumber_data: null,
      chart: {
        datasets: [
          {
            data: [4, 3, 3, 5, 5],
            backgroundColor: [
              '#0d0dc2',

              '#ffd908',
              '#ff9a08',
              '#0dc240',
              '#44b6f0'
            ]
          }
        ],
        type: 'doughnut',
        options: {
          legend: {
            display: false
          },
          cutoutPercentage: 60,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      total_instruksi: 0,
      jumlah_baru: 0,
      jumlah_on_going: 0,
      jumlah_done: 0,
      jumlah_close: 0,
      jumlah_verifikasi: 0,
      listIKPperSKPD: [],
      listIKPperSKPDPersen: [],
      ListPopUpIKP: [],
      nama_skpd: '',
      limit: 10,
      limitPersen: 10
    };
  },
  mounted() {
    this.LoadChart();
  },

  methods: {
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    },
    LoadChart() {
      window.axios
        .post(window.base_api + 'page/ikp', {
          tanggal: window.date_now
        })
        .then(response => {
          if (response.status == 200) {
            this.sumber_data = response.data.sumber_data;
            this.chart.datasets[0].data = [
              parseInt(response.data.jumlah_baru),
              parseInt(response.data.jumlah_close),
              parseInt(response.data.jumlah_verifikasi),
              parseInt(response.data.jumlah_done),
              parseInt(response.data.jumlah_on_going)
            ];
            this.setChart();
            this.total_instruksi = response.data.total_instruksi;
            this.jumlah_baru = response.data.jumlah_baru;
            this.jumlah_on_going = response.data.jumlah_on_going;
            this.jumlah_done = response.data.jumlah_done;
            this.jumlah_close = response.data.jumlah_close;
            this.jumlah_verifikasi = response.data.jumlah_verifikasi;
            this.listIKPperSKPD = response.data.IKPperSKPD;
            this.listIKPperSKPD = this.listIKPperSKPD.sort(this.compareJumlah);
            this.listIKPperSKPDPersen = response.data.IKPperSKPD;
            this.listIKPperSKPDPersen = this.listIKPperSKPDPersen.sort(
              this.comparePersen
            );
          }
        });
    },
    modalPopUp(nama_skpd, detail) {
      this.ListPopUpIKP = detail.sort(this.comparePopUp);
      this.nama_skpd = nama_skpd;
    },
    setChart() {
      var ctx = document.getElementById('ikp-chart').getContext('2d');
      let chartData = {
        datasets: this.chart.datasets
      };

      new window.Chart(ctx, {
        type: this.chart.type,
        data: chartData,
        options: this.chart.options
      });
    },
    limitChange() {
      this.limit += 5;
    },
    limitChangePersen() {
      this.limitPersen += 5;
    },
    compareJumlah(a, b) {
      a = a.jumlah_ikp;
      b = b.jumlah_ikp;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    },
    comparePersen(a, b) {
      a = a.persentase_ikp;
      b = b.persentase_ikp;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    },
    comparePopUp(a, b) {
      a = a.progress;
      b = b.progress;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    }
  },
  computed: {
    IKPperSKPD() {
      return this.listIKPperSKPD.slice(0, this.limit).sort(this.compareJumlah);
    },
    IKPperSKPDPersen() {
      return this.listIKPperSKPDPersen
        .slice(0, this.limitPersen)
        .sort(this.comparePersen);
    }
  }
};
</script>

<style>
.bg-baru {
  background: #0d0dc2;
}

.bg-close {
  background: #fdc730;
}

.bg-verifikasi {
  background: #ff9a08;
}

.bg-done {
  background: #17c12b;
}

.bg-on-going {
  background: #01a7e5;
}
</style>
